
import { defineComponent, reactive } from "vue";
import {
  getComic,
  updateComicsDoc,
  getComicsMaxId,
} from "@/components/firebaseOperations";
import constants from "@/components/constants";
import router from "@/router";
import store from "@/store";
import KProgress from "k-progress-v3";

export default defineComponent({
  components: {
    KProgress,
  },
  async setup() {
    const state = reactive({
      comics: [] as any[],
      currentIndexOfComics: 0,
      isStart: false,
      comicsMaxId: 0,
      typeCount: 0,
      missTypeCount: 0,
      intervalId: 0,
      countdown: 600,
      eachTypeChar: [] as any[],
      typeChar: "",
      isBeforeHiraganaNn: false,
      isBeforeHiraganaTu: false,
      missTyping: false,
      pressingDownShift: false,
    });

    const loadImage = (_url: string) => {
      var xhr;
      xhr = new XMLHttpRequest();
      xhr.open("HEAD", _url, false); //同期モード
      xhr.send(null);
      return xhr.status;
    };

    const getImageForNotFount = () => {
      return {
        children_available: true,
        id: 0,
        image_available: true,
        image_url: "../assets/images/notFoundImage.png",
        input_string: "gazougamitukarimasendesita",
        site_url: "https://type-comic.work/",
        title: "画像が見つかりませんでした",
      };
    };

    const setRandomId = async () => {
      let randomId = 0;
      let comic = {} as { [key: string]: any };
      let loopCount = 0;
      do {
        loopCount++;
        if (loopCount >= 1001) {
          comic = getImageForNotFount();
          break;
        }

        randomId = Math.floor(Math.random() * state.comicsMaxId) + 1;
        comic = await getComic(randomId);
        if (!comic.image_available) {
          continue;
        } else {
          const existImage = loadImage(comic.image_url);
          if (existImage !== 200) {
            updateComicsDoc(comic.docId);
            continue;
          }
        }
      } while (state.comics.findIndex((e) => e.title == comic.title) != -1);

      return comic;
    };

    const setEachTypeChar = () => {
      state.typeChar = "";
      state.eachTypeChar = [];
      state.comics[state.currentIndexOfComics].title_hiragana.forEach(
        (element: string) => {
          if (element in constants.INPUT_TABLE) {
            const elementKeyType = constants.INPUT_TABLE[element][0];
            if (state.eachTypeChar.length >= 1) {
              if (
                state.eachTypeChar.slice(-1)[0].hiragana === "ん" &&
                elementKeyType[0] === "n"
              ) {
                state.eachTypeChar.slice(-1)[0].inputString = "nn";
              } else if (state.eachTypeChar.slice(-1)[0].hiragana === "っ") {
                state.eachTypeChar.slice(-1)[0].inputString = elementKeyType[0];
              }
            }

            state.eachTypeChar.push({
              hiragana: element,
              inputString: elementKeyType,
            });
          } else {
            state.eachTypeChar.push({
              hiragana: element,
              inputString: element,
            });
          }
        }
      );

      if (state.eachTypeChar.slice(-1)[0].hiragana === "ん") {
        state.eachTypeChar.slice(-1)[0].inputString = "nn";
      }
    };

    const getComics = async () => {
      for (let i = 0; i < 5; i++) {
        await addComic();
      }

      setEachTypeChar();
    };

    const addComic = async () => {
      const comic = await setRandomId();
      state.comics.push(comic);
    };

    const checkAnotherWord = (key: string) => {
      if (state.eachTypeChar[0].hiragana in constants.INPUT_TABLE) {
        let tempInputedTypedCharForAHiragana = state.typeChar;
        tempInputedTypedCharForAHiragana += key;

        let newTypeKeyIndex = constants.INPUT_TABLE[
          state.eachTypeChar[0].hiragana
        ].findIndex((e) => e.indexOf(tempInputedTypedCharForAHiragana) === 0);

        if (newTypeKeyIndex !== -1) {
          const newTypeKey =
            constants.INPUT_TABLE[state.eachTypeChar[0].hiragana][
              newTypeKeyIndex
            ];

          state.typeChar = tempInputedTypedCharForAHiragana;
          state.eachTypeChar[0].inputString = newTypeKey;

          state.typeCount++;
        } else {
          state.missTypeCount++;
          state.missTyping = true;
        }
      } else {
        state.missTypeCount++;
        state.missTyping = true;
      }

      return;
    };

    const updateTypeChar = (key: string) => {
      state.typeChar += key;

      if (state.typeChar === state.eachTypeChar[0].inputString) {
        state.typeChar = "";

        if (state.eachTypeChar[0].inputString === "n") {
          state.isBeforeHiraganaNn = true;
        } else if (
          state.eachTypeChar[0].hiragana === "っ" &&
          constants.INPUT_TABLE["っ"].indexOf(
            state.eachTypeChar[0].inputString
          ) === -1
        ) {
          state.isBeforeHiraganaTu = true;
        }

        state.eachTypeChar = state.eachTypeChar.slice(1);
      }
    };

    const reset = () => {
      state.isStart = false;
      state.currentIndexOfComics = 0;
      state.comics = [];
      state.typeCount = 0;
      state.missTypeCount = 0;
      state.missTyping = false;
      clearInterval(state.intervalId);
      state.countdown = 600;
      state.eachTypeChar = [];
    };

    const keydownListener = async (e: any) => {
      let key = e.key.toLowerCase();

      if (state.pressingDownShift) {
        key = key.toUpperCase();
      }

      if (state.isStart) {
        if (key === "escape") {
          // escape
          reset();
          await getComics();
        } else if (key === "shift") {
          state.pressingDownShift = true;
        } else {
          state.typeCount++;
          state.missTyping = false;
          if (
            state.eachTypeChar[0].inputString[state.typeChar.length] === key
          ) {
            state.isBeforeHiraganaNn = false;
            state.isBeforeHiraganaTu = false;
            updateTypeChar(key);
            if (state.eachTypeChar.length === 0) {
              state.currentIndexOfComics++;
              setEachTypeChar();
              addComic();
            }
          } else if (state.isBeforeHiraganaTu) {
            state.missTypeCount++;
            state.missTyping = true;
          } else if (state.isBeforeHiraganaNn && key === "n") {
            state.isBeforeHiraganaNn = false;
          } else if (
            state.eachTypeChar[0].hiragana === "っ" &&
            constants.INPUT_TABLE["っ"].indexOf(
              state.eachTypeChar[0].inputString
            ) === -1
          ) {
            if (key === "l" || key === "x") {
              state.eachTypeChar[0].inputString = "tu";
            } else {
              state.missTypeCount++;
              state.missTyping = true;
            }
          } else {
            checkAnotherWord(key);
          }
        }
      } else {
        if (e.key === " ") {
          // スペース
          if (state.eachTypeChar.length !== 0) {
            state.isStart = true;
            startCountdown();
          }
        }
      }
    };

    const keyupListener = async (e: any) => {
      const key = e.key.toLowerCase();
      if (key === "shift") {
        state.pressingDownShift = false;
      }
    };

    const setKeydownEventListener = () => {
      window.addEventListener("keydown", keydownListener);
    };

    const setKeyUpEventListener = () => {
      window.addEventListener("keyup", keyupListener);
    };

    const setStore = () => {
      store.commit("setComics", state.comics);
      store.commit("setIndexOfComics", state.currentIndexOfComics);
      store.commit("setTypeCount", state.typeCount);
      store.commit("setMissTypeCount", state.missTypeCount);
    };

    const startCountdown = () => {
      state.intervalId = setInterval(function () {
        state.countdown--;
        if (state.countdown <= 0) {
          clearInterval(state.intervalId);
          setStore();
          window.removeEventListener("keydown", keydownListener);
          window.removeEventListener("keyup", keyupListener);
          router.push({ name: "Result" });
        }
      }, 100);
    };

    state.comicsMaxId = await getComicsMaxId();
    await getComics();
    setKeydownEventListener();
    setKeyUpEventListener();

    return { state };
  },
});
