import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
  updateDoc,
} from "firebase/firestore";
import store from "@/store";
import constants from "./constants";

export async function getComicsMaxId(): Promise<number> {
  let maxId = 0;
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.COMICS),
    orderBy("id", "desc"),
    limit(1)
  );

  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    maxId = doc.data().id;
  });

  return maxId;
}

export async function getComic(id: number): Promise<{ [key: string]: any }> {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.COMICS),
    where("id", "==", id)
  );

  const snapshot = await getDocs(q);
  let doc = {} as { [key: string]: any };
  snapshot.forEach((docParam: any) => {
    const docData = docParam.data();
    docData["docId"] = docParam.id;
    doc = docData;
  });

  return doc;
}

/// マンガ取得
/// @return {Map} - マンガ
export async function getComics(comicsIdToUse: number[]): Promise<any[]> {
  const q = query(
    collection(store.state.db, constants.FIREBASE_COLLECTION.COMICS),
    where("image_available", "==", true),
    where("id", "in", comicsIdToUse)
  );

  const snapshot = await getDocs(q);
  const docs = [] as any[];
  snapshot.forEach((doc: any) => {
    const docData = doc.data();
    docData["docId"] = doc.id;
    docs.push(docData);
  });

  return docs;
}

export async function updateComicsDoc(docId: string): Promise<void> {
  const ref = doc(store.state.db, constants.FIREBASE_COLLECTION.COMICS, docId);
  await updateDoc(ref, {
    image_available: false,
    updated_at: new Date(),
  });
}
